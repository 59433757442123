<template>
  <div>
    <validation-observer
      ref="otpForm"
      #default="{invalid}"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              v-if="verifyingRequired"
              label-for="otp"
              :label="$t('auth.Verification code')"
            >
              <validation-provider
                #default="{ errors }"
                vid="otp"
                rules="required|digits:4"
                :name="$t('Code')"
              >
                <b-input-group
                  class="input-group-merge otp"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="otp"
                    ref="otpInput"
                    v-model="otp"
                    placeholder="0000"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    size="24"
                    :maxlength="4"
                    :disabled="!!(otpDelay)"
                    @keypress="numbersOnly($event)"
                  />
                  <b-input-group-append
                    :disabled="!!(otpDelay)"
                    is-text
                  >
                    <b-avatar
                      :variant="getOtpAvatarVariant()"
                      size="16"
                    >
                      <feather-icon
                        v-if="!otpVerifying"
                        size="12"
                        icon="KeyIcon"
                      />
                      <b-spinner
                        v-if="otpVerifying"
                        small
                        type="grow"
                      />
                    </b-avatar>
                  </b-input-group-append>
                </b-input-group>

                <small
                  v-for="error in errors"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              v-if="otpInvalid && !otpVerifying && verifyingRequired"
              label-for="otp"
              :label="otpInvalid?$t('auth.Did not get the code?'):''"
            >
              <b-button
                v-if="otpInvalid && !otpVerifying"
                variant="outline-secondary"
                block
                :disabled="!!(otpDelay)"
                @click="getOtp(phone)"
              >
                <b-spinner
                  v-if="otpGetting"
                  small
                  type="grow"
                />
                <span class="align-middle">{{ $t('auth.Resend') }} <small>({{ otpAttemptsLeft }})</small></span>
              </b-button>
            </b-form-group>
          </b-col>
          <b-button
            v-if="otpDelay && verifyingRequired"
            variant="flat-secondary"
            block
            :disabled="true"
          >
            <feather-icon
              icon="ClockIcon"
            />
            <span class="align-middle">
              {{ $t('auth.Try again after') }} {{ otpDelay|secondsToTimer() }}
            </span>
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>

import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'

import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BForm, BButton, VBTooltip, BSpinner, BAvatar,
} from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'
import { required, digits } from '@validations'
import { numbersOnly, translateApiErrors } from '@/mixins/functions'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { secondsToTimer } from '@/filters/dateTime'
import useAuth from '../useAuth'
// Install and Activate the Ukrainian locale.
export default {
  directives: {
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },
  filters: {
    secondsToTimer,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BButton,
    BSpinner,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [numbersOnly],
  props: {
    verifyingRequired: {
      type: Boolean,
      required: true,
    },
    newUserPhone: {
      type: String,
      default: '',
    },
    resetPassword: {
      type: Boolean,
      required: false,
      default: false,
    },
    password: {
      type: String,
      required: false,
      default: '',
    },
    passwordConfirmation: {
      type: String,
      required: false,
      default: '',
    },
    phone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      otp: null,
      otpInvalid: false,
      otpGetting: false,
      otpVerifying: false,
      otpAttemptsLeft: null,
      otpDelay: null,
      phoneVerified: false,
      required,
      digits,
      responseData: {},
      getOtpResponse: {},
    }
  },

  watch: {
    otp(value) {
      if (value && value.length === 4) {
        this.verifyOtp()
      }
    },
    verifyingRequired(value) {
      if (value) {
        this.$nextTick(() => this.$refs.otpInput.focus())
      }
    },
    newUserPhone(newPhone) {
      if (newPhone) {
        this.getOtp(newPhone)
      }
    },
    responseData: {
      deep: true,
      async handler(value) {
        await this.$nextTick(() => {
          this.handleResponseData(value)
        })
      },
    },
  },

  methods: {
    getVerifyOtpData() {
      const data = {}
      if (this.resetPassword) {
        data.dispatchType = 'account/verifyResetOtp'
        data.payload = {
          phone: this.phone,
          code: this.otp,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        }
      } else {
        data.dispatchType = 'account/verifyOtp'
        data.payload = {
          phone: this.phone,
          code: this.otp,
        }
      }

      return data
    },
    getOtpAvatarVariant() {
      if (this.phoneVerified) return 'success'
      if (this.otpInvalid) return 'danger'
      return 'secondary'
    },
    handleResponseData(response) {
      if (response.status !== 'verified') {
        this.otpAttemptsLeft = response.attempts_left
        this.otpDelay = response.next_attempt_in_seconds || 0
        this.otpDelayTimer()
        this.$refs.otpForm.setErrors(
          { otp: [`${this.$t('auth.One Time Password not found')}`] },
        )
      }
      switch (response.status) {
        case 'not_found':
          this.otpInvalid = true
          this.otpVerifying = false
          break
        case 'sent':
          this.otpInvalid = false
          this.otpVerifying = false
          break
        case 'already_sent':
          this.otpInvalid = true
          this.otpVerifying = false
          break
        case 'no_more_attempts':
          this.otpInvalid = true
          this.otpVerifying = false
          this.addUnverifiedPhone({ phone: response.phone, delay: response.next_attempt_in_seconds })
          break
        case 'verified':
          this.otpInvalid = false
          this.otpVerifying = false
          this.phoneVerified = true
          this.removeUnverifiedPhone(response.phone)
          setTimeout(() => {
            this.$emit('phoneVerified')
          }, 1000)

          break
        default:
      }
    },
    otpDelayTimer() {
      if (this.otpDelay > 0) {
        const timer = setInterval(() => {
          this.otpDelay -= 1
          if (this.otpDelay <= 0) clearInterval(timer)
        }, 1000)
      }
    },

    getOtp(newPhone) {
      const phone = newPhone || this.phone
      const dispatchType = this.resetPassword ? 'account/getResetOtp' : 'account/getOtp'
      this.otpGetting = true
      return store.dispatch(dispatchType, { phone })
        .then(response => {
          this.$emit('verifyingRequired', true)
          this.getOtpResponse = response.data.data
          this.otpAttemptsLeft = response.data.data.attempts_left
          this.otpGetting = false
          if (response.status === 201) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('auth.Verification code has been sent'),
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: `${this.$t('auth.Please, check you phone')}`,
              },
            })
          }
        })
        .catch(error => {
          if (error?.response?.data?.errors?.phone_verified_at !== undefined) {
            this.phoneVerified = true
            return setTimeout(() => {
              this.$emit('phoneVerified')
            }, 1000)
          }
          this.otpGetting = false
          return this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('Something went wrong'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `${this.$t('Please, contact our support team')}`,
            },
          })
        })
        .then(() => {
          this.otp = null
        })
    },
    verifyOtp() {
      const data = this.getVerifyOtpData()
      this.otpVerifying = true
      return store.dispatch(data.dispatchType, data.payload)
        .then(response => {
          this.responseData = response.data.data
        })
        .catch(error => {
          const { errors } = error.response.data
          translateApiErrors(errors)
          this.$refs.authForm.setErrors(
            error.response.data.errors,
          )
        })
    },
  },
  setup() {
    const { addUnverifiedPhone, removeUnverifiedPhone } = useAuth()
    return {
      addUnverifiedPhone,
      removeUnverifiedPhone,
    }
  },
}
</script>
