<template>
  <div

    class="text-left"
  >
    <b-overlay
      :show="submittingFetch"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
    >
      <b-tabs
        v-if="serviceOffers.length"
        justified
        nav-class="font-weight-bold warning justify-content-between"
        pills
      >
        <b-tab
          v-for="(offer,key) in serviceOffers"
          :key="key"
          :title="getTypeTitle(offer.point_type.slug)"
        >
          <b-row>
            <b-col
              class="mb-1"
            >
              <offer-about
                :about-data="offer"
                :short="short"
              />
            </b-col>
            <b-col
              v-if="!short && offer.media_files.length"
              cols="12"
              md="6"
              class="d-flex align-items-stretch justify-content-center"
            >
              <offer-media
                :media-files="offer.media_files"
                :display="!short"
                :el-id="key.toString()"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>
<script>
import {
  BTab, BTabs, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import { serviceOptions } from '@/mixins/options'
import OfferOpenMap from '@/views/apps/service/offer/details/OfferOpenMap.vue'
import OfferAbout from '@/views/apps/service/offer/details/OfferAbout.vue'
import { ref } from '@vue/composition-api'
import OfferMedia from '@/views/apps/service/offer/details/OfferMedia.vue'

export default {
  components: {
    BTab,
    BTabs,
    BRow,
    BCol,
    BOverlay,
    OfferMedia,
    OfferAbout,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      OfferOpenMap,
      tabIndex: '',
    }
  },

  setup(props) {
    const serviceOffers = ref([])
    const submittingFetch = ref(false)
    if (props.user && props.user.service_offers) {
      serviceOffers.value = props.user.service_offers
    }

    const { getPurposeTitle, getTypeTitle } = serviceOptions
    return {
      getPurposeTitle,
      getTypeTitle,
      serviceOffers,
      submittingFetch,
    }
  },

}
</script>
<style lang="scss" scoped>
.tab-pane.card-body{
  padding-top: 0!important;
}
</style>
