import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'

export const useReferralOptions = () => {
  const refPagePath = '/r'

  const refTypes = [
    {
      id: 'refi',
      description: 'Invitation',
    },
    {
      id: 'refp',
      description: 'Promo',
    },

  ]

  return {
    refTypes,
    refPagePath,
  }
}

export const useReferral = () => {
  const fetchingReferrals = ref(false)
  const { refPagePath } = useReferralOptions()

  const setRef = (key, value) => localStorage.setItem(key, value)

  const getRefInvitation = () => localStorage.getItem('refi')

  const refQueryHandler = () => {
    const { route, router } = useRouter()
    const invitationCode = route.value.query.refi
    if (invitationCode) {
      setRef('refi', invitationCode)
      router.push({ name: 'pages-landing' })
    }
  }

  const getRefLink = (code, refTypeID) => {
    // by default set the Invitation referral key
    const refKey = refTypeID || 'refi'

    let refCode = code
    if (!refCode) {
      refCode = store.getters['referral/referralCode'].code
    }
    if (refCode) {
      const path = window.location.origin + refPagePath
      const url = new URL(path)
      const params = url.searchParams
      params.append(refKey, refCode)
      return url.toString()
    }
    return false
  }

  const getCodeObj = code => {
    if (!code) {
      return store.getters['referral/referralCode']
    }
    const codes = store.getters['referral/referralCodes']
    return codes.find(obj => obj.code === code)
  }

  const fetchReferrals = () => {
    fetchingReferrals.value = true
    const payload = {
      order: 'desc',
      per_page: 100,
      sort_by: 'id',
      include: 'service_offers.point_purposes',
    }
    store.dispatch('referral/getReferrals', payload)
      .then(response => {
        const successStatuses = [200, 201]
        if (successStatuses.includes(response.status)) {
          store.commit('referral/setReferrals', response.data.data)
        }
      }).catch(error => {
        const { errors } = error.response.data
        console.log(errors)
      })
      .finally(() => {
        fetchingReferrals.value = false
      })
  }

  const getRefLinkByCode = code => {
    let text = ''
    const codeObj = getCodeObj(code)
    if (codeObj) {
      text = getRefLink(codeObj.code)
    }
    return text
  }
  const getRefLinkAndDescriptionByCode = code => {
    let text = ''
    const codeObj = getCodeObj(code)
    if (codeObj) {
      const link = getRefLink(codeObj.code)
      text = codeObj.description ? `${codeObj.description} ${link}` : link
    }
    return text
  }

  return {
    refQueryHandler,
    getRefInvitation,
    getRefLink,
    getRefLinkByCode,
    getRefLinkAndDescriptionByCode,
    fetchReferrals,
    fetchingReferrals,
  }
}
