<template>
  <div>
    <div
      v-if="canUpdateAccount()"
      class="header"
    >

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        class="my-2"
        @click="$router.push({ name: 'pages-account-setting'})"
      >
        <feather-icon
          icon="EditIcon"
          class="mr-25 d-none d-sm-inline"
        />
        <span class="align-middle">{{ $t('Edit') }}</span>
      </b-button>

    </div>

    <b-card
      class="profile-header mb-2"
      alt="cover photo"
      body-class="p-0"
      header=" "
      header-bg-variant="light-warning"
    >
      <div class="position-relative d-none d-sm-block">
        <div class="profile-img-container d-flex align-items-stretch justify-content-center">
          <div class="profile-img">
            <b-avatar
              rounded
              size="117"
              variant="light-warning"
              :src="user.avatar"
              :text="getUserAvatarText(user.name)"
            />
          </div>
          <div class="profile-title ml-2">
            <h2>
              {{ user.name }}
            </h2>
            <div class="pb-1">
              <profile-languages
                :user="user"
              />
              <profile-rating />
            </div>
          </div>
        </div>
      </div>
      <div class="d-sm-none text-center">
        <profile-avatar :user="user" />
        <h2>{{ user.name }}</h2>
        <profile-rating />
        <profile-languages :user="user" />
      </div>

      <div class="actions d-flex justify-content-center justify-content-md-end align-items-center m-1 ">
        <profile-share-button
          :user="user"
        />
      </div>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BCardText, BAvatar, BButton, BCardFooter,
} from 'bootstrap-vue'
import ProfileShareButton from '@/views/pages/profile/ProfileShareButton.vue'
import ProfileLanguages from '@/views/pages/profile/ProfileLanguages.vue'

import ProfileAvatar from '@/views/pages/profile/ProfileAvatar.vue'
import { getUserAvatarText } from '@/mixins/functions'
import defineAbilityFor from '@/libs/acl/defineAbility'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ProfileRating from '@/views/pages/profile/ProfileRating.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BAvatar,
    BButton,
    BCardFooter,

    ProfileShareButton,
    ProfileLanguages,
    ProfileAvatar,
    ProfileRating,
  },
  directives: {
    Ripple,
  },
  mixins: [getUserAvatarText],
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props) {
    const user = store.getters['account/user']
    const ability = defineAbilityFor(user)
    const canUpdateAccount = () => ability.can('manage', 'Account') || props.user.id === user.id
    return {
      canUpdateAccount,
    }
  },
}
</script>
<style lang="scss">
.actions{
  gap: 1rem!important;
}
</style>
<style lang="scss" scoped>

.actions .btn {
  height: 100%;
  padding: 1rem;
}

@media (min-width: 768px) and (orientation:landscape) {
  .card .card-header {
    min-height: 10rem!important;
  }
}
</style>
