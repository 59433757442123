<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header>
        <b-card-title>
          <div class="d-flex">
            <h4 class="mb-0 ml-50" />
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body class="align-items-center">
        <b-row>
          <b-col
            cols="12"
            md="6"
            offset-md="3"
          >
            <b-form-radio
              v-model="ownRequest"
              name="ownRequest"
              :value="true"
              class="custom-control-primary mb-1"
            >
              {{ $t('point.own_request') }}
            </b-form-radio>
            <b-form-radio
              v-model="ownRequest"
              name="ownRequest"
              :value="false"
              class="custom-control-warning mb-1"
            >
              {{ $t('point.request_on_behalf') }}
            </b-form-radio>

            <div
              v-if="!ownRequest"
              class="mt-sm-2 mt-md-4"
            >
              <!--managed-->
              <b-form-group>
                <b-form-checkbox
                  v-model="pointData.managed"
                  checked="true"
                  name="managed-request"
                  switch
                  inline
                  class="mt-1"
                >
                  {{ $t('point.managed_request') }}
                </b-form-checkbox>

                <p
                  v-if="pointData.managed"
                  class="mt-1 small"
                >
                  {{ $t('point.managed_request_desc') }}
                </p>

              </b-form-group>
              <!-- user name -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('point.user_name')"
                  rules="required|min:4"
                >
                  <b-form-input
                    id="userName"
                    ref="userName"
                    v-model="pointData.user_name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('common.John Doe')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--phone-->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('point.user_phone')"
                  vid="phone"
                  rules="required"
                >
                  <VuePhoneNumberInput
                    id="phone"
                    v-model="phone.number"
                    :error="phone.valid!==true"
                    name="phone"
                    valid-color="#28C76F"
                    :required="true"
                    :translations="{
                      countrySelectorLabel: $t('Country code'),
                      countrySelectorError: $t('Choose a country'),
                      phoneNumberLabel: $t('Phone Number'),
                      example: $t('Example:')
                    }"
                    :only-countries="countriesList"
                    :default-country-code="countryCode"
                    @update="onPhoneUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!--                <div v-if="phone.valid">-->
                  <!--                  <small>{{ $t('auth.We might ask you to enter a verification code that we send to this phone' ) }}</small>-->
                  <!--                </div>-->

                </validation-provider>
              </b-form-group>

            </div>

          </b-col>

        </b-row>

      </b-card-body>

    </b-card>

  </div>

</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BFormRadio,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardHeader,
  BAlert,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  required, confirmed, min,
} from '@validations'

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { locales } from '@/mixins/options'

export default {
  components: {
    ValidationProvider,
    BFormRadio,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BAlert,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BFormCheckbox,
    VuePhoneNumberInput,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pointData: this.data,
      required,
      confirmed,
      min,
      ownRequest: true,
      phone: {
        number: '',
        valid: false,
        country: undefined,
      },

    }
  },
  computed: {
    countryCode() {
      const countryCode = localStorage.getItem('countryCode')
      return countryCode || 'UA'
    },

  },

  methods: {
    onPhoneUpdate({
      countryCode, isValid, phoneNumber, countryCallingCode, formattedNumber,
    }) {
      this.phone.valid = isValid
      if (isValid) {
        this.pointData.user_phone = formattedNumber.replace(/\+/g, '')
      }
    },
  },
  setup() {
    const { countriesList } = locales()
    return {
      countriesList,
    }
  },
}
</script>
