export const capitalizeFirstLetter = {
  bind(el, _, vnode) {
    // uppercase transform
    el.transformUpper = function () {
      const start = el.selectionStart
      el.value = el.value.charAt(0).toUpperCase() + el.value.slice(1)
      el.setSelectionRange(start, start)
      vnode.componentInstance.$emit('input', el.value.charAt(0).toUpperCase() + el.value.slice(1))
    }
    // add event listener
    el.addEventListener('input', () => {
      el.transformUpper()
    })
  },
  unbind(el) {
    el.removeEventListener('input', el.transformUpper)
  },
}
