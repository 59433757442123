<template>
  <div>
    <div
      v-if="languages"
    >
      <!--      <b-avatar-->
      <!--        v-for="language in languages"-->
      <!--        :key="language.locale"-->
      <!--        v-b-tooltip.hover-->
      <!--        class="pull-up languages"-->
      <!--        :title="language.name"-->
      <!--        :src="language.img"-->
      <!--        square-->
      <!--      />-->
      <b-badge
        v-for="language in languages"
        :key="language.locale"
        variant="light-secondary"
        class="languages"
      >
        {{ language.name }}
      </b-badge>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  VBTooltip,

} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import { locales } from '@/mixins/options'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {

  },
  setup(props) {
    const { localesList } = locales()

    const languages = computed({
      get: () => {
        if (props.user.languages) {
          return localesList.filter(locale => props.user.languages.includes(locale.locale))
        }
        return []
      },
    })

    return {
      languages,
    }
  },
}

</script>
<style scoped>
  .languages{
    margin: 0.2rem;
  }
</style>
