<template>
  <div>
    <b-alert
      :show="!user.hasOwnProperty('id')&& !submittingFetch"
      variant="danger"
    >
      <h4 class="alert-heading">
        {{ $t('Error fetching user data') }}
      </h4>
      <div class="alert-body">
        {{ $t('No user found with this id') }}

      </div>
    </b-alert>
  </div>

</template>

<script>
import {
  BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    submittingFetch: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
}

</script>
