<template>
  <div>
    <div class="pb-1">
      <b-badge
        v-for="purpose in aboutData.point_purposes"
        :key="purpose.slug"
        pill
        variant="light-primary"
      >
        {{ getPurposeTitle(purpose.slug) }}
      </b-badge>

      <b-badge
        v-if="aboutData.number_of_views"
        pill
        variant="light-secondary"
      >
        <feather-icon
          icon="EyeIcon"
          class="mr-25"
        />
        <small>
          {{ aboutData.number_of_views }}
        </small>
      </b-badge>

    </div>
    <div>
      <h4>
        {{ aboutData.name }}
      </h4>
    </div>
    <p v-if="!short">
      {{ aboutData.description }}
    </p>
    <!-- media -->
    <b-media
      v-if="aboutData.address.country!==null "
      no-body
      class="pb-1"
    >
      <b-media-aside class="mr-1">
        <b-avatar
          rounded
          variant="light-primary"
          size="34"
        >
          <feather-icon
            icon="MapPinIcon"
            size="18"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <offer-open-map
          :data="aboutData"
        />
      </b-media-body>
    </b-media>
    <b-media
      v-if="aboutData.location_radius!==null "
      no-body
    >
      <b-media-aside class="mr-1">
        <b-avatar
          rounded
          variant="light-primary"
          size="34"
        >
          <feather-icon
            icon="RadioIcon"
            size="18"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        {{ $t('Within a radius of') }}
        <h6 class="mb-0">
          {{ aboutData.location_radius }} {{ $t('km') }}
        </h6>
      </b-media-body>
    </b-media>
    <!--          <b-media-->
    <!--            no-body-->
    <!--          >-->
    <!--            <b-media-aside class="mr-1">-->
    <!--              <b-avatar-->
    <!--                rounded-->
    <!--                variant="light-primary"-->
    <!--                size="34"-->
    <!--              >-->
    <!--                <feather-icon-->
    <!--                  icon="CalendarIcon"-->
    <!--                  size="18"-->
    <!--                />-->
    <!--              </b-avatar>-->
    <!--            </b-media-aside>-->
    <!--            <b-media-body>-->
    <!--              <h6 class="mb-0">-->
    <!--                Sat, May 25, 2020-->
    <!--              </h6>-->
    <!--              <small>10:AM to 6:PM</small>-->
    <!--            </b-media-body>-->
    <!--          </b-media>-->

  </div>
</template>

<script>
import {
  BMedia, BMediaAside, BAvatar, BMediaBody, BBadge,
} from 'bootstrap-vue'
import { formatTimeOfStay, getFormattedAddress, getPostStatusProperty } from '@/mixins/functions'
import Ripple from 'vue-ripple-directive'
import { serviceOptions } from '@/mixins/options'
import { formatDateTime } from '@/filters/dateTime'
import OfferOpenMap from './OfferOpenMap.vue'

export default {
  components: {
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BBadge,
    OfferOpenMap,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateTime,
  },
  mixins: [formatTimeOfStay, getFormattedAddress, getPostStatusProperty],
  props: {
    aboutData: {
      type: Object,
      required: true,
      default: () => {},
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { getPurposeTitle } = serviceOptions
    return {
      getPurposeTitle,
    }
  },

}
</script>
