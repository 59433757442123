export default function auth() {
  const addUnverifiedPhone = data => {
    let phones = localStorage.getItem('unverifiedPhones')
    const t = new Date()
    t.setSeconds(t.getSeconds() + data.delay)
    if (phones) {
      phones = JSON.parse(phones)
      phones[data.phone] = { delay: t }
    } else {
      phones = {}
      phones[data.phone] = { delay: t }
    }

    return localStorage.setItem('unverifiedPhones', JSON.stringify(phones))
  }
  const removeUnverifiedPhone = phone => {
    let phones = localStorage.getItem('unverifiedPhones')
    if (phones) {
      phones = JSON.parse(phones)
      delete phones[phone]
      return localStorage.setItem('unverifiedPhones', JSON.stringify(phones))
    }
    return false
  }
  const isPhoneUnverified = phone => {
    let phones = localStorage.getItem('unverifiedPhones')
    if (phones === undefined || phones === null || phones === 'null') return false
    phones = JSON.parse(phones)
    const unverifiedPhone = phones[phone]
    if (unverifiedPhone) {
      const t = new Date()
      return (new Date(unverifiedPhone.delay) - t) > 0
    }
    return false
  }

  return {
    addUnverifiedPhone,
    removeUnverifiedPhone,
    isPhoneUnverified,
  }
}
