<template>
  <div id="networkStatus" />
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: false,
      connectionStatus: '',
    }
  },
  watch: {
    connectionStatus(v) {
      if (v.length !== 0) {
        this.displayConnectionStatus()
      }
    },
    onLine(v) {
      if (v) {
        this.showBackOnline = true
        this.$emit('connection-restored')
        this.connectionStatus = this.$t('message.connection_restored')
        setTimeout(() => {
          this.showBackOnline = false
          this.connectionStatus = ''
        }, 1000)
      }
    },
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    navigator.connection.addEventListener('change', this.onConnectionChange)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
    navigator.connection.removeEventListener('change', this.onConnectionChange)
  },
  methods: {
    displayConnectionStatus() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `${this.$t('Network')}`,
          icon: 'AlertTriangleIcon',
          variant: 'warning',
          text: this.connectionStatus,
        },
      })
    },
    onConnectionChange() {
      const {
        rtt, downlink, effectiveType, saveData,
      } = navigator.connection
      this.connectionStatus = (downlink && downlink < 5) ? this.$t('message.connection_slow') : ''
    },
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online'
      this.connectionStatus = !this.onLine ? this.$t('message.connection_lost') : ''
    },

  },
}
</script>
