import { firebase } from '@/views/apps/notification/firebase/firebase'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'

export const pushNotification = () => {
  const { createFcmSubscription, getMessage } = firebase()
  return {
    createFcmSubscription,
    getMessage,
  }
}
export const webNotification = () => {
  const notifications = ref([])

  // Pagination count <= required by pagination component
  const totalNotifications = ref(null)
  const readNotification = notification => {
    if (!notification.read_at) {
      store.dispatch('notification/readNotification', notification.id)
        .then(response => {
          const successStatuses = [200, 201]
          if (successStatuses.includes(response.status)) {
            const notificationsArr = store.getters['notification/notifications']
            const index = notificationsArr.findIndex(n => n.id === notification.id)
            notificationsArr[index].read_at = response.data.read_at
          }
        })
    }
  }
  const submittingFetchNotifications = ref(false)
  const fetchNotifications = (...args) => {
    submittingFetchNotifications.value = true
    store.dispatch('notification/fetchNotifications', ...args).then(response => {
      submittingFetchNotifications.value = false
      const { total } = response.data.meta
      totalNotifications.value = total
    })
  }

  const submittingReadAll = ref(false)
  const readAllNotifications = () => {
    submittingReadAll.value = true
    store.dispatch('notification/readAllNotifications').then(response => {
      if (response.status === 200) {
        const notificationsArr = store.getters['notification/notifications']
        const date = new Date().toISOString()
        const updatedData = notificationsArr.map(n => (n.read_at == null ? { ...n, read_at: date } : n))
        store.commit('notification/SET_NOTIFICATIONS', updatedData)
      }
      submittingReadAll.value = false
    })
  }

  const notificationIsUnread = notification => !notification.read_at

  return {
    notifications,
    totalNotifications,
    submittingFetchNotifications,
    fetchNotifications,
    submittingReadAll,
    readAllNotifications,
    readNotification,
    notificationIsUnread,
  }
}
export const notificationFiltersSortingAndPagination = () => {
  const filters = ref({
    unread: 'unread',
    page: 1,
    perPage: 6,
    order: 'desc',
  })

  const filterOptions = {
    perPage: [6, 9, 24, 72],
  }

  // Sorting
  const sortBy = ref({ text: 'Submit date', value: 'submit_date' })
  const sortByOptions = [
    { text: 'Submit date', value: 'submit_date' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}
export const manageNotificationActions = () => {
  const { router } = useRouter()
  const { readNotification } = webNotification()
  const openEvent = notification => {
    router.push({ name: 'event-calendar-edit', params: { id: notification.data.event_id } }).then(() => {
      readNotification(notification)
    })
  }
  const getRequestRoute = notification => {
    let name = 'apps-search-service-request-details'
    const types = ['EventWithdrawnNotification', 'EventCreatedNotification', 'EventUpdatedNotification']

    if (types.includes(notification.type)) {
      name = 'apps-service-request-info'
    }
    return { name, params: { id: notification.data.service_request_id }, hash: '#proposals' }
  }

  const openServiceRequest = notification => {
    router.push(getRequestRoute(notification)).then(() => {
      readNotification(notification)
    }).catch(err => {
      if (err.name !== 'NavigationDuplicated') {
        throw err
      }
    })
  }

  return {
    openEvent,
    openServiceRequest,
  }
}
