<template>
  <div>
    <b-modal
      id="modal-recaptcha"
      centered
      size="sm"
      :title="$t('auth.Are you human or robot?')"
      hide-footer
      hide-header-close
    >
      <div class="captcha">

        <span v-if="!recaptchaRendered">
          <b-spinner small />
          {{ $t('auth.Checking if you are human') }}
        </span>
        <vue-recaptcha
          ref="recaptcha"
          :sitekey="recaptchaSiteKey"
          :language="$i18n.locale"
          @verify="onReCaptchaVerified"
          @expired="onReCaptchaExpired"
          @render="onReCaptchaRendered"
        />
        <!--        <span><small class="text-secondary">{{ $t('auth.Robots are not allowed to register, sorry(') }}</small></span>-->
      </div>
    </b-modal>
  </div>
</template>

<script>

import {
  BRow, BCol, BSpinner, BContainer,
} from 'bootstrap-vue'
import { VueRecaptcha } from 'vue-recaptcha'

export default {

  components: {
    BRow,
    BCol,
    BSpinner,
    BContainer,

    VueRecaptcha,
  },

  props: {
    humanoidVerifyingRequired: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      recaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaRendered: false,
    }
  },

  watch: {
    humanoidVerifyingRequired(value) {
      if (value) {
        this.$bvModal.show('modal-recaptcha')
      }
    },
  },

  methods: {
    onReCaptchaVerified(recaptchaToken) {
      this.$emit('humanoid-verified', recaptchaToken)
      this.$refs.recaptcha.reset()
      this.$bvModal.hide('modal-recaptcha')
    },
    onReCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },
    onReCaptchaRendered() {
      this.recaptchaRendered = true
    },
  },
  setup() {

  },
}
</script>
<style scoped>
.captcha{
  text-align: -webkit-center;
}
</style>
